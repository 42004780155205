<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        label-width="55px"
        :queryInfo.sync="queryInfo"
        :filter-keys="['managerUserId']"
        @on-search="renderTable">
        <el-form-item label="校区" prop="campusId">
          <el-select v-model="queryInfo.campusId" @change="campusChecked" clearable filterable size="small">
            <el-option v-for="{campusName,id} in campusList" :key="id" :label="campusName" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋" prop="buildId">
          <el-select v-model="queryInfo.buildId" @change="buildChecked" clearable filterable size="small">
            <el-option v-for="{value,label} in buildList" :key="value" :label="label" :value="value" />
          </el-select>
        </el-form-item>
        <el-form-item label="楼层" prop="floorNo">
          <el-select v-model="queryInfo.floorNo" @change="floorChange" clearable filterable size="small">
            <el-option v-for="num in floorList" :key="num" :label="`第${num}层`" :value="num" />
          </el-select>
        </el-form-item>
        <el-form-item label="课室" prop="classroomId">
          <el-select v-model="queryInfo.classroomId" clearable filterable size="small">
            <el-option v-for="{label,id} in classRoomList" :key="id" :label="label" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态" prop="auditStatus" label-width="70px">
          <el-select v-model="queryInfo.auditStatus" clearable filterable
                     size="small">
            <el-option v-for="{paramValue, paramName} in $store.state.systemParam.reviewStatus" :key="paramValue"
                       :label="paramName"
                       :value="paramValue" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="teachBuildName" label="所在楼栋" width="100" />
      <el-table-column prop="classroomName" label="申请课室" width="100" align="center" />
      <el-table-column prop="stuName" label="申请人" width="100" />
      <el-table-column label="申请人班级/院系/部门" min-width="230">
        <template v-slot="{row}">
          {{ row.classesName ? row.classesName : (row.deptName ? row.deptName : row.collegeName ? row.collegeName : '')
          }}
        </template>
      </el-table-column>
      <el-table-column label="申请人电话" prop="phone" min-width="140" align="center" />
      <el-table-column label="开始时间" width="180" align="center">
        <template v-slot="{row}">
          {{ row.startTime | formatDate('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" width="180" align="center">
        <template v-slot="{row}">
          {{ row.endTime | formatDate('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column prop="applyReason" label="申请理由" min-width="200" />
      <el-table-column prop="auditText" label="驳回原因" min-width="200" />
      <el-table-column prop="auditStatus" label="申请状态" width="100" fixed="right" align="center">
        <template v-slot="{row}">
          <el-tag v-if="row.auditStatus"
                  :type="$store.state.systemParam.reviewStatus[row.auditStatus-1].btnType">
            {{ $store.state.systemParam.reviewStatus[row.auditStatus - 1].paramName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template v-slot="{row}">
          <!-- 未审批状态 才有按钮-->
          <template v-if="row.auditStatus === 1">
            <el-button v-permission="['class:review']" type="success" size="small"
                       @click="handleApprove(row,3)">通过
            </el-button>
            <el-button v-permission="['class:review']" type="danger" size="small"
                       @click="handleApprove(row, 2)">驳回
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 驳回审核 -->
    <reject-reason ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import rejectReason from './dialog/rejectReason.vue'
import { treeForClassroom } from '@/api/teachBuild'
import { pageClassroomApplyApi, saveClassroomApply } from '@/api/teachClassroom'
import { getCampusList } from '@/api/campus'
import { copyData } from '@/utils/common-js'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'classRoomApplicate',
  mixins: [tableView],
  components: {
    rejectReason
  },
  data () {
    return {
      visibleDialog: false,
      queryInfo: {
        managerUserId: null, // 教学楼管理员id
        collegeId: this.$store.state.login.userInfo.collegeId || null,
        auditStatus: null,
        buildId: null,
        campusId: null,
        classroomId: null,
        dormitoryInfoId: null,
        floorNo: null
      },
      curShow: false,
      expandedKeys: [],
      teachBuildTree: [],
      campusList: [],
      buildList: [],
      floorList: 0,
      classRoomList: [],
      tableData: [],
      loading: false,
      curTree: []
    }
  },
  watch: {
    'queryInfo.campusId': function (newVal) {
      // 监控 校区campusId变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.buildList)
      }
    },
    'queryInfo.buildId': function (newVal) {
      // 监控 buildId变化,及时清空 子级的list
      if (!newVal) {
        this.floorList = 0
      }
    },
    'queryInfo.classroomId': function (newVal) {
      // 监控 floorNo变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.classRoomList)
      }
    }
  },
  mounted () {
    this.getCampusList()
  },
  methods: {
    // 查询教室申请列表
    async renderTable (pageNum) {
      // 只有开发人员跟 超级管理员可以加载全部数据，其他的根据角色加载教室使用申请
      if (this.$store.state.login.isAdmin) {
        this.queryInfo.managerUserId = null
      } else {
        this.queryInfo.managerUserId = this.$store.state.login.userInfo.id
      }
      await this.getTableData(pageClassroomApplyApi, pageNum)
    },
    // 获取校区列表
    getCampusList () {
      this.loading = true
      getCampusList({}).then((res) => {
        this.campusList = res.data.list
        treeForClassroom().then((treeRes) => {
          this.teachBuildTree = treeRes.data
          this.getBuildList()
        })
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取楼栋列表
    getBuildList () {
      this.buildList = []
      this.teachBuildTree.map((item) => {
        if (item.campusId === this.queryInfo.campusId) {
          const data = {
            value: item.id,
            label: item.label,
            floorNum: item.floors.length
          }
          this.buildList.push(data)
        }
      })
    },

    // 获取教室列表
    getClassroomList () {
      this.teachBuildTree.map((item) => {
        if (item.id === this.queryInfo.buildId) {
          this.classRoomList =
            item.floors[this.queryInfo.floorNo - 1].classrooms
        }
      })
    },

    // 校区选择
    campusChecked (e) {
      this.queryInfo.campusId = e
      this.buildList = []
      this.floorList = 0
      this.classRoomList = []
      this.queryInfo.buildId = ''
      this.queryInfo.floorNo = ''
      this.queryInfo.classroomId = ''
      if (e) {
        this.getBuildList()
      }
    },

    // 楼栋选择
    buildChecked (e) {
      this.queryInfo.buildId = e
      const data = this.buildList.filter(item => item.value === e)
      this.floorList = 0
      this.classRoomList = []
      this.queryInfo.floorNo = ''
      this.queryInfo.classroomId = ''
      if (data.length) {
        this.floorList = data[0].floorNum
      }
    },
    // 楼层变化
    floorChange (val) {
      this.queryInfo.classroomId = ''
      this.classRoomList = []
      if (val) {
        this.getClassroomList()
      }
    },
    // 审核教室使用申请 3=通过申请 2=打开驳回弹窗
    handleApprove (row, flag) {
      if (flag === 3) { // 同意
        const _msg = `是否通过此教室使用申请:<br/>申请人：<b>${row.stuName}</b><br/>教室：<b>${row.teachBuildName}${row.classroomName}</b>`
        const _msgType = 'success'
        const _successMsg = '审核通过'
        this.$confirm(_msg, '审核申请', {
          type: _msgType,
          dangerouslyUseHTMLString: true
        }).then(async () => {
          try {
            await saveClassroomApply({
              id: row.id,
              auditStatus: flag
            })
            this.$message({
              message: _successMsg,
              type: _msgType
            })
            this.renderTable()
          } catch (e) {
          }
        })
      } else if (flag === 2) { // 打开驳回弹窗
        this.visibleDialog = true
        // 给子组件赋值
        this.$nextTick(() => {
          copyData(row, this.$refs.dialogRef.readData)
          this.$refs.dialogRef.formData.id = row.id
          this.$refs.dialogRef.setInitData()
        })
      }
    }
  }
}
</script>
